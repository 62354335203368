/**
 * Manage global libraries like jQuery or THREE from the package.json file
 */

// Import Packages
import 'svgxuse'
import { gsap } from 'gsap'
import inlineSVG from 'inline-svg'
import SlimSelect from 'slim-select'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

// Import modules
import Video from './modules/video'
import Sticky from './modules/sticky'
import ImageBanner from './modules/ImageBanner'
import Navigation from './modules/navigation'
import PageNav from './modules/page-nav'

const App = {
    initSticky() {
        new Sticky({
            elem: '.site-header',
        })
     },
    initMobileNav() {
        new Navigation({
            elem: '#mobile-navigation',
            openBtn: '#mobile-button-open',
            closeBtn: '#mobile-button-close',
            navMenu: '#mobile-navigation-menu'
        })
    },
    initLazyLoadImageBanner() {
        new ImageBanner()
    },
    initVideoBanner() {
        new Video({
            elem: '.banner-video',
            playPause: '#play-pause',
            sound: '#sound'
        })
    },
    initInlineSVG() {
        inlineSVG.init({
            svgSelector: 'img.inline-svg'
        })
    },
    initAnimation() {
        const animate = document.querySelectorAll('.animate')

        if (animate.length > 0) {

            animate.forEach((elem) => {
                ScrollTrigger.create({
                    trigger: elem,
                    toggleClass: 'show',
                    start: "top center",
                    end: "+=0",
                    toggleActions: "play none none none",
                    // markers: true, // debugging
                    once: true
                })
            })

        }
    },
    initPageNav() {
        new PageNav()
    },
    init() {

        gsap.registerPlugin(ScrollTrigger)

        this.initLazyLoadImageBanner()
        this.initSticky()
        this.initMobileNav()
        this.initVideoBanner()
        this.initInlineSVG()
        this.initAnimation()
        this.initPageNav()
    }
}

window.addEventListener('DOMContentLoaded', () => App.init())