import _ from 'lodash'

class Sticky {

    constructor(props) {
        this.props = props
        this.init()
    }

    init() {

        if ( typeof this.props.elem === 'undefined' ) return

        this.header = document.querySelector(this.props.elem)

        this.addStickyScroll()

    }

    addStickyScroll() {
        if ( this.header == null ) return
        
        window.addEventListener('scroll', _.throttle(() => {
            if(window.pageYOffset > this.header.offsetTop) {
                this.header.classList.add('sticky')
            } else {
                this.header.classList.remove('sticky')
            }
        }, 250))
    }
}

export default Sticky