import gsap from 'gsap/all'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

class PageNav {
	constructor(props) {
		this.props = props
		
		this.init()
	}

	init() {
		const links = document.querySelectorAll('a[href^="#"]')
		const header = document.querySelector('.site-header')
		const scrollOffset = header.offsetHeight + header.offsetTop + 20

		links.forEach( link => {
			link.addEventListener('click', e => {
				e.preventDefault()
				gsap.to(window, {
					duration: 1,
					scrollTo: {
						y: e.target.getAttribute('href'),
						offsetY: scrollOffset
					},
				})
			})
        
			const id = link.getAttribute('href')

			try {
				const row = document.querySelector(id)
				if (row) {
					ScrollTrigger.create({
						trigger: row,
						start: "top center",
						end: "bottom center",
						toggleClass: {
							targets: link,
							className: 'active-link'
						}
					})
				}
			} catch { }
		})
	}
}

export default PageNav